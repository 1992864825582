<template>
  <div id="reviewReport">
    <el-dialog
      :title="reviewReportFormTitle"
      width="680px"
      :visible.sync="reviewReportDialogVisible"
      :close-on-click-modal="false"
      @close="reviewReportDialogClose"
    >
      <el-form
        ref="reviewReportFormRef"
        :model="reviewReportForm"
        :rules="reviewReportFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="reviewReportForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品代号" prop="productNo">
              <el-input v-model="reviewReportForm.productNo" placeholder="请输入产品代号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评审人员" prop="reviewers">
              <el-input
                v-model="reviewReportForm.reviewers"
                placeholder="请输入评审人员"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评审意见" prop="reviewOpinion">
              <el-input
                v-model="reviewReportForm.reviewOpinion"
                placeholder="请输入评审意见"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评审意见总结" prop="reviewOpinionSummary">
              <el-input
                v-model="reviewReportForm.reviewOpinionSummary"
                placeholder="请输入评审意见总结"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="评审负责人" prop="principal">
              <el-input v-model="reviewReportForm.principal" placeholder="请输入评审负责人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="signatureDate">
              <el-date-picker v-model="reviewReportForm.signatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="reviewReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="reviewReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="reviewReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="productNo" label="产品代号" />
      <el-table-column prop="reviewers" label="评审人员" />
      <el-table-column prop="reviewOpinion" label="评审意见" />
      <el-table-column prop="reviewOpinionSummary" label="评审意见总结" />
      <el-table-column prop="principal" label="评审负责人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.signatureDate">{{ scope.row.signatureDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="reviewReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addReviewReport, deleteReviewReport, updateReviewReport, selectReviewReportInfo, selectReviewReportList } from '@/api/technology/reviewReport'

export default {
  data () {
    return {
      reviewReportDialogVisible: false,
      reviewReportFormTitle: '',
      reviewReportForm: {
        id: '',
        productName: '',
        productNo: '',
        reviewers: '',
        reviewOpinion: '',
        reviewOpinionSummary: '',
        principal: '',
        signatureDate: ''
      },
      reviewReportFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      reviewReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectReviewReportList(this.searchForm).then(res => {
      this.reviewReportPage = res
    })
  },
  methods: {
    reviewReportDialogClose () {
      this.$refs.reviewReportFormRef.resetFields()
    },
    reviewReportFormSubmit () {
      if (this.reviewReportFormTitle === '设计开发评审报告详情') {
        this.reviewReportDialogVisible = false
        return
      }
      this.$refs.reviewReportFormRef.validate(async valid => {
        if (valid) {
          if (this.reviewReportFormTitle === '新增设计开发评审报告') {
            await addReviewReport(this.reviewReportForm)
          } else if (this.reviewReportFormTitle === '修改设计开发评审报告') {
            await updateReviewReport(this.reviewReportForm)
          }
          this.reviewReportPage = await selectReviewReportList(this.searchForm)
          this.reviewReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.reviewReportFormTitle = '新增设计开发评审报告'
      this.reviewReportDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteReviewReport(row.id)
        if (this.reviewReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.reviewReportPage = await selectReviewReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.reviewReportFormTitle = '修改设计开发评审报告'
      this.reviewReportDialogVisible = true
      this.selectReviewReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.reviewReportFormTitle = '设计开发评审报告详情'
      this.reviewReportDialogVisible = true
      this.selectReviewReportInfoById(row.id)
    },
    selectReviewReportInfoById (id) {
      selectReviewReportInfo(id).then(res => {
        this.reviewReportForm.id = res.id
        this.reviewReportForm.productName = res.productName
        this.reviewReportForm.productNo = res.productNo
        this.reviewReportForm.reviewers = res.reviewers
        this.reviewReportForm.reviewOpinion = res.reviewOpinion
        this.reviewReportForm.reviewOpinionSummary = res.reviewOpinionSummary
        this.reviewReportForm.principal = res.principal
        this.reviewReportForm.signatureDate = res.signatureDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectReviewReportList(this.searchForm).then(res => {
        this.reviewReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectReviewReportList(this.searchForm).then(res => {
        this.reviewReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectReviewReportList(this.searchForm).then(res => {
        this.reviewReportPage = res
      })
    }
  }
}
</script>

<style>
</style>
